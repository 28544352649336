import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-date-wrapper" }
const _hoisted_3 = ["type", "id", "placeholder", "pattern", "maxlength", "value", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-input', { 'error': _ctx.error }])
  }, [
    _createElementVNode("label", {
      class: "body-text",
      for: _ctx.label
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        ref: "inputRef",
        type: _ctx.currentType,
        id: _ctx.label,
        placeholder: _ctx.placeholder,
        class: _normalizeClass(['links', 'date-input', { 'has-icon': _ctx.icon }]),
        pattern: _ctx.pattern,
        maxlength: _ctx.maxlength,
        value: _ctx.formattedDate,
        readonly: _ctx.readonly,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
      }, null, 42, _hoisted_3),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "custom-date-icon iatse-icon-calendar",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
          }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}