<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <div class="row">
            <div class="col-md-12 text-center">
                <p> Documents on File For <span> {{ dependentName }} </span> </p>
            </div>
        </div>
        <div v-if="documents.length > 0">
            <p class="mb-4">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</p>
            <table class="small mb-4">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in documents" :key="index">
                        <td>
                            <a :href="$router.resolve({ name: 'smartsearch.file.view', params: { documentId: document.DocumentID } }).href" target="_blank">
                                {{ document.Type.Description }}
                            </a>
                        </td>
                        <td>{{ document.Date }}</td>
                        <td :class="{ 'text-danger': document.Status === 'Pending' }">
                            {{ document.Status }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="text-center mb-4 mt-4"> No documents have been uploaded to your account.</div>

        <!-- Actions -->
        <div class="account-actions">
            <div class="action-buttons">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary">Back</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, defineComponent } from 'vue';
import { Document } from '@interfaces/interfaces';
import { LocationQueryValue, useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent ({
    props: {
        modalProps: Object,
    },
    setup(props, { emit }) {
        const documents: Ref<Document[] | []> = ref([]);
        const dependentName: Ref<string> = ref('');
        const loading = ref(true);
        const route = useRoute();
        const DependentID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const PartnerID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const Status: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const headers = [
            'Dependent Name',
            'Relationship',
            'DOB',
        ];

        const fetchData = async () => {
            loading.value = true;
            // PartnerID.value = route.query.PartnerID;
            // DependentID.value = route.query.DependentID;
            // Status.value = route.query.Status;

            await axios.get('api/participant/dependents/documents', {
                    params: {
                        ...props.modalProps
                    }
                })
                .then(response => {
                    documents.value = response.data.Documents;
                    dependentName.value = response.data.DependentName;
                    console.log(response.data, 'documents');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        const closeModal = () => {
            emit('close');
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            documents,
            dependentName,
            headers,
            loading,
            closeModal
        };
    },
});
</script>