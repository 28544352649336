import { TableData, DependentData } from "@/interfaces/interfaces";
import { defineStore } from "pinia";
import axios from 'axios';

interface DependentPerson {
  Relationship: number;
  CitizenshipCode: number;
  DateofBirth: string | null;
  FirstName: string | null;
  MiddleName: string | null;
  LastName: string | null;
  NameSuffix: number;
  MaritalStatus: number;
  MarriageDate: string | null;
  Salutation: number;
  Sex: number;
  SSN: string | null;
  SIN: string | null;
  OtherID: string | null;
  SSN_NA: boolean | null;
}

const initialDependent = {
  Relationship: 0,
  CitizenshipCode: 1031,
  DateofBirth: null,
  FirstName: null,
  MiddleName: null,
  LastName: null,
  NameSuffix: 0,
  MaritalStatus: 0,
  MarriageDate: null,
  Salutation: 60,
  Sex: 0,
  SSN: null,
  SIN: null,
  OtherID: null,
  SSN_NA: null,
};

export const useDependentStore = defineStore({
    id: "dependent",
    state: () => ({
        Person: {...initialDependent} as DependentPerson,
        Email: null as string| null,
        PhoneNumber: null as string | null,
        Documents: [],
        dependents: {
          headers: [
              { title: 'Dependent Name' },
              { title: 'Relation' },
              { title: 'DOB' },
              { title: 'Gender' },
              { title: 'Coverage Effective Date' },
              { title: 'Coverage End Date' },
              { title: 'Status' },
              { title: 'Date of Marriage' },
              { title: '' }
          ],
          rows: []
        } as TableData,
        data: null as DependentData | null,
        documentTypes: [] as DocumentType[],
      }),

    actions: {
      async fetchDependents() {
        await axios.get('api/participant/dependents')
            .then(response => {
              this.dependents.rows = Object.values(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
      },

      async fetchGeneralData() {
        await axios.get('api/participant/dependents/add')
          .then(response => {
              this.data = response.data;
              this.documentTypes = response.data.DocumentTypeDetails
          })
          .catch(error => {
              console.error('There was an error!', error);
          });
      },

      clearDependent() {
        this.Person = {...initialDependent};
        this.Email = null;
        this.PhoneNumber = null;
        this.Documents = [];
      },

      resetDependentStore() {
        this.$reset();
      },
    }
  })

// Create a reactive store with the initial state
// export const dependentStore = reactive(deepCopy(initialDependentState));

// // Function to reset the store to its initial state
// export const resetDependentStore = () => {
//     Object.assign(dependentStore, deepCopy(initialDependentState));
//   };

// // Utility function for deep copying
// function deepCopy(obj: DependentState) {
//     return JSON.parse(JSON.stringify(obj));
// }