<template>
    <AlertsComponent :success="success" :errors="errors" />
    <!-- <h3 class="text-center mb-4"> {{ data?.Title }} </h3> -->
    <div class="container mt-4">
        <div class="row">
            <LoaderComponent v-if="loading" />
            <form v-else>
                <DependentStep1 v-if="!isValidStep1" @check-data="checkData" @close="closeModal"/>
                <DependentStep2 v-else :submitting="loadingSubmit" @check-data="checkData" @submit-data="handleSubmit" />
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, defineComponent } from 'vue';
import { useDependentStore } from '@/stores/dependent';
import { useModalStore } from '@/stores/modal';

import DependentStep1 from '@components/participant/dependents/DependentStep1.vue';
import DependentStep2 from '@components/participant/dependents/DependentStep2.vue';
import axios from 'axios';

export default defineComponent ({
    components: {
        DependentStep1,
        DependentStep2,
    },
    setup(_, {emit}) {
        const modalStore = useModalStore();
        const dependentStore = useDependentStore();
        const route = 'api/participant/dependents/add';
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const isValidStep1 = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const isModalVisible = ref(false);
        const openModal = modalStore.openModal;

        const checkData = (isValid: boolean) => {
            isValidStep1.value = isValid;
        }

        const closeModal = () => {
            emit('close');
        }

        const handleSubmit = () => {
            loadingSubmit.value = true;
            const formData = {
                Person: dependentStore.Person,
                Email: dependentStore.Email,
                PhoneNumber: dependentStore.PhoneNumber,
                Documents:dependentStore.Documents
            };

            axios.post(route, { ...formData, _method: "PATCH" })
                .then((response) => {
                    loadingSubmit.value = false;
                    isModalVisible.value = true;

                    if (response.data.success) {
                        openModal('success-modal', null, 'modal-success', null, {
                            title: 'Success',
                            content: {
                                type: "text",
                                value: response.data.success,
                                icon: 'iatse-icon-user-verified'
                            },
                        })

                        dependentStore.fetchDependents();
                        dependentStore.clearDependent();
                    }
                    else {
                        errors.value.push('Something went wrong. Please try again.')
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                }).finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3000);
                })
        };

        const fetchData = async () => {
            await dependentStore.fetchGeneralData();
            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            isValidStep1,
            loading,
            success,
            errors,
            isModalVisible,
            loadingSubmit,
            checkData,
            closeModal,
            handleSubmit,
        };
    }
});
</script>