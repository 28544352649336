<template>
    <div :class="['custom-select links', customClass]">
        <label v-if="label" class="body-text" :for="label"> {{ label }} </label>
        <div :class="['select-selected filter-arrows', { 'error': error }]" @click="toggleDropdown">
            <p class="text">{{ internalSelectedOption.value }}</p>
            <div v-if="singleArrow && !isSearchable" class="arrows" :class="{ 'single': singleArrow }">
                <i class="iatse-icon-arrow-down"></i>
            </div>
            <div v-else-if="!isSearchable" class="arrows">
                <i class="iatse-icon-select-arrow"></i>
                <i class="iatse-icon-select-arrow"></i>
            </div>
        </div>
        <div class="select-items" v-if="isDropdownOpen && !readonly && type !== 'filters'">
            <div v-if="!displayedOptions.length" class="select-option">
                No options
            </div>
            <div v-for="option in displayedOptions" :key="option.key" class="select-option"
                :class="{ active: option.key == internalSelectedOption.key }" @click="selectOption(option)">
                {{ option.value }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { SelectOption } from '@/interfaces/interfaces'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        selectedOption: {
            type: Object as () => SelectOption,
            default: null,
        },
        options: {
            type: Array as () => SelectOption[] | [],
            default: () => [],
        },
        hasDefaultOption: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        singleArrow: {
            type: Boolean,
            default: false,
        },
        isSearchable: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const isDropdownOpen = ref(false);
        const internalSelectedOption = ref(props.selectedOption || { key: 0, value: '-Select Option' });

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const selectOption = (option: SelectOption) => {
            internalSelectedOption.value = option;
            emit('update:selectedOption', option);
            emit('change', option);
            isDropdownOpen.value = false;
        };

        const displayedOptions = computed(() => {
            const defaultOption: SelectOption = { key: -0, value: '-' };

            if (props.hasDefaultOption) {
                return [defaultOption, ...props.options];
            }
            return props.options;
        });


        watch(() => props.selectedOption,
            (newOption) => {
                console.log(newOption, '@new option')
                internalSelectedOption.value = newOption || { key: 0, value: '-Select Option' }
            },
            { deep: true }
        );

        return {
            isDropdownOpen,
            internalSelectedOption,
            displayedOptions,
            toggleDropdown,
            selectOption,
        };
    },
});
</script>