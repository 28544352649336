<template>
  <div :class="['drag-drop', customClasses]" ref="dragDropArea" @dragover.prevent @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
    <div class="choose-files" v-if="!files.length">
      <i class="iatse-icon-document-upload"></i>
      <h5 v-if="text"> {{ text }} </h5>
      <h5 v-else>
        Drag and Drop or
        <span class="browse" @click.prevent="triggerFileInput">Choose From Device</span>
      </h5>
      <input type="file" ref="fileInput" style="display: none;" multiple @change="handleFileChange" />
    </div>

    <div class="drag-drop-files">
      <div v-for="(file, index) in files" :key="index" class="file-preview">
        <img src="../../assets/images/doc.png" :alt="file.name" class="file-image-preview" width="95" height="55" />
        <small class="file-name">{{ file.name }}</small>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    customClasses: {
      type: String,
      default: '',
    },
    text: String,
    reset: Boolean,
  },
  setup(props, { emit }) {
    const files = ref<File[]>([]);
    const fileInput = ref<HTMLInputElement | null>(null);
    const dragDropArea = ref<HTMLDivElement | null>(null);

    watch(() => props.reset, (newValue) => {
      if (newValue) {
        files.value = [];
      }
    });

    const handleDragEnter = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '2px dashed #8EC986';
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '1px dashed #8EC986';
      }
    };

    const handleDrop = (event: DragEvent) => {
      if (event.dataTransfer && event.dataTransfer.files) {
        files.value = [...files.value, ...Array.from(event.dataTransfer.files)];
      }

      emit('change', files.value);
    };

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files) {
        files.value = [...files.value, ...Array.from(target.files)];
      }

      emit('change', files.value);
    };

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    }

    return {
      files,
      fileInput,
      dragDropArea,
      handleDrop,
      handleDragLeave,
      handleDragEnter,
      triggerFileInput,
      handleFileChange
    };
  },
});
</script>