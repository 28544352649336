<template>
    <AlertsComponent :success="success" />
    <LoaderComponent v-if="loading"/>
    <div v-else class="plan-content">
        <div class="text-card">
            <p class="text-500-bold">Please read the messages below. When you are done, check the box next to the
                message and it will be archived. </p>
        </div>
        <div v-if="alerts.length" class="alerts-wrapper">
            <template v-for="(alertItem, key) in alerts" :key="key">
                <div class="alerts-header">
                    <p class="text-500-bold">
                        {{ alertItem.key }}
                    </p>
                    <div class="alerts-header-alert-checkbox" v-if="key === 0">
                        <CheckBox label="Mark as Read" className="alert-checkbox" @change="readAll" :showLabel="true"
                            :left="true" />
                    </div>
                </div>
                <div class="alerts-cards">
                    <div class="bordered-card" v-for="alert in alertItem.value" :key="alert.AlertID">
                        <div class="bordered-card-header">
                            <!-- <h5> Coverage expires soon </h5> -->
                            <p class="text-grey"> {{ useDateFormatter(alert.DateCreated) }}</p>
                        </div>

                        <div class="bordered-card-body">
                            <p v-html="alert.Message"> </p>
                            <CheckBox label="Mark as Read" className="alert-checkbox" v-model:modelValue="alert.AlertRead"
                                :showLabel="false" @change="markRead(alert.AlertID, alert.AlertRead)" />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="row">
            <p class="col-md-12 text-center mb-4">No alerts</p>
        </div>
    </div>
</template>

<script lang="ts">
import CheckBox from '../form/CheckBox.vue';

import { Ref, onMounted, ref } from 'vue';
import { useDateFormatter } from "@/utils/dateFormatter";
import { useOptionsFormatter } from "@/utils/optionFormatter";
import { useAuthStore } from '@/stores/auth';
import { SelectOption } from '@/interfaces/interfaces';
import axios from 'axios';

export default {
    components: {
        CheckBox,
    },
    setup() {
        const alerts: Ref<SelectOption[] | []> = ref([]);
        const authStore = useAuthStore();
        const loading = ref(true);
        const success = ref('');

        const markRead = async (alertId: number, status: string | boolean) => {
            const changeStatusUrl = status ? `api/participant/alerts/read/${alertId}` : `api/participant/alerts/unread/${alertId}`;

            console.log(changeStatusUrl, '@change');

            await axios.get(changeStatusUrl)
                .then(response => {
                    success.value = response.data.success;
                    alerts.value = useOptionsFormatter(response.data.unreadAlerts);
                    authStore.updateUnreadAlerts(response.data.unreadAlertsCount);
                    setTimeout(() => success.value = '', 2000);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        const readAll = async () => {
            await axios.get('api/participant/alerts/readAll')
                .then(response => {
                    success.value = response.data.success;
                    alerts.value = useOptionsFormatter(response.data.unreadAlerts);
                    authStore.updateUnreadAlerts(response.data.unreadAlertsCount);
                    setTimeout(() => success.value = '', 2000);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/alerts')
                .then(response => {
                    alerts.value = useOptionsFormatter(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            alerts,
            loading,
            success,
            readAll,
            markRead,
            useDateFormatter,
        };
    },
};
</script>
