import {
    Person,
    AddressInfo,
    ContactInfo,
    Contact,
    CountryStates,
    SelectOption,
} from "@/interfaces/interfaces";
import { useOptionsFormatter } from "@utils/optionFormatter";
import { defineStore } from "pinia";
import axios from "axios";

type ProfileChange = {
    [key: string]: boolean;
}

export const useProfileStore = defineStore("profile", {
    state: () => ({
        personalData: null as Person | null,
        documentTypes: [] as SelectOption[],
        loading: false,
        changes: {} as Record<keyof ProfileChange, boolean>,
        // changes: [] as ProfileChange[],
        errors: [] as string[],
        address: null as AddressInfo | null,
        addressTypes: [] as SelectOption[],
        statesByCountry: null as CountryStates[] | null,
        countries: null as SelectOption[] | null,
        contact: null as ContactInfo | null,
        emails: [] as Contact[],
        phones: [] as Contact[],
        phoneTypes: [] as SelectOption[],
        generalData: false as boolean,
        maritalStatuses: [] as SelectOption[],
        nameSuffixes: [] as SelectOption[],
        salutations: [] as SelectOption[],
        relationships: [] as SelectOption[],
        dependentRelationships: [] as SelectOption[],
        genders: [] as SelectOption[],
    }),
    actions: {
        async fetchPersonalData(route: string) {
            this.loading = true;
            this.errors = [];

            try {
                const response = await axios.get(route);
                this.personalData = response.data?.Person;
                this.changes = response.data?.Changes;
                this.documentTypes = response.data.DocumentTypes;
                this.loading = false;
            } catch (error) {
                console.error("Error fetching personal info:", error);
                this.loading = false;
            }
        },

        async fetchAddress(route: string) {
            this.loading = true;
            this.errors = [];

            try {
                const response = await axios.get(route);
                this.address = response.data?.AddressMethod;
                this.addressTypes = useOptionsFormatter(
                    response.data?.AddressTypes
                );
                this.loading = false;
            } catch (error) {
                console.error("Error fetching personal info:", error);
                this.loading = false;
            }
        },

        async fetchContactData(route: string) {
            this.loading = true;
            this.errors = [];

            try {
                const response = await axios.get(route);
                this.contact = response.data?.Contact;
                this.emails = response.data?.Emails;
                this.phones = response.data?.Phones;
                this.phoneTypes = useOptionsFormatter(
                    response.data?.PhoneTypes
                );
                this.loading = false;
            } catch (error) {
                console.error("Error fetching contact info:", error);
                this.loading = false;
            }
        },

        async fetchCountriesStates() {
            try {
                const response = await axios.get("api/user/countries-states");
                this.countries = useOptionsFormatter(response.data?.Countries);
                this.statesByCountry = response.data?.StatesByCountry;
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        },

        async fetchGeneralData() {
            try {
                const response = await axios.get("api/user/general-person-data");
                this.generalData = true;
                this.maritalStatuses = useOptionsFormatter(response.data.MaritalStatuses);
                this.nameSuffixes = useOptionsFormatter(response.data.NameSuffixes);
                this.salutations = useOptionsFormatter(response.data.Salutations);
                this.genders = useOptionsFormatter(response.data?.Genders);
                this.relationships = useOptionsFormatter(response.data?.Relationships);
                this.dependentRelationships = useOptionsFormatter(response.data?.DependentRelationships);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        },

        setPersonalData(data: Person) {
            this.personalData = data;
        },

        setAddress(data: AddressInfo) {
            this.address = data;
        },

        setContact(data: ContactInfo) {
            this.contact = data;
        },

        addPhoneNumber() {
            const newPhone: Contact = {
                ContactInfo: "",
                Primary: { key: 0, value: "No" },
                TypeCode: 8,
            }

            this.phones.push(newPhone);
        },

        addEmailAddress() {
            const newEmail: Contact = {
                ContactInfo: "",
                Primary: { key: 0, value: "No" },
            }

            this.emails.push(newEmail)
        },

        resetUserProfile() {
            this.personalData = null;
            this.address = null;
            this.contact = null;
        },
    },
});
