<template>
    <div class="row">
        <div v-if="data" class="col-md-12 mb-4">
            <p class="text-center">
                Enter the information your spouse/domestic partner and/or each dependent you wish to add.
                This information must be submitted with documentation regarding their relationship to you (Step 2).
                Our
                administrative team will review your request(s) prior making them part of your permanent record.
                Note that all fields marked with an asterisk are required fields.
            </p>
            <h6> STEP 1 - Dependent Specific Information </h6>
            <div class="form-group row mb-4">
                <div class="col-md-6 d-flex align-items-center gap-4">
                    <label for="relationship" class="col-md-2 col-form-label text-md-right">
                        Relationship <span class="text-danger">*</span>
                    </label>
                    <select class="form-control" :class="{ 'is-invalid': errors?.Relationship }"
                        v-model="dependentStore.Person.Relationship" required>
                        <option value="0"> Select Relationship </option>
                        <option v-for="(relationship, key) in Object.entries(data.Relationships)"
                            :value="relationship[0]" :key="key">
                            {{ relationship[1] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row d-flex align-items-end mb-4">
                <div class="col-md-4">
                    <label for="country" class="col-form-label text-md-right">
                        Country <span class="text-danger">*</span>
                    </label>
                    <select class="form-control" id="country" v-model="dependentStore.Person.CitizenshipCode" required>
                        <option v-for="country in data.Countries" :value="country.ShortName" :key="country.ShortName">
                            {{ country.LongName }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4" v-if="displaySecurityNumber('SSN')">
                    <label for="ssn" class="col-form-label text-md-right">
                        Social Security # <span class="text-danger">*</span>
                    </label>
                    <input type="text" id="ssn" v-model="dependentStore.Person.SSN" class="form-control"
                        :class="{ 'is-invalid': errors?.SSN }" pattern="\d{3}-\d{2}-\d{4}" placeholder="***-**-****"
                        @input="formatSSN" maxlength="11">
                </div>
                <div class="col-md-4" v-if="displaySecurityNumber('SIN')">
                    <label for="sin" class="col-form-label text-md-right"> SIN # <span
                            class="text-danger">*</span></label>
                    <input type="text" id="sin" v-model="dependentStore.Person.SIN" class="form-control"
                        :class="{ 'is-invalid': errors?.SIN }" maxlength="9">
                </div>
                <div class="col-md-4" v-if="displaySecurityNumber('OtherID')">
                    <label for="other_id" class="col-form-label text-md-right">
                        Other ID # <span class="text-danger">*</span></label>
                    <input type="text" id="other_id" v-model="dependentStore.Person.OtherID" class="form-control"
                        :class="{ 'is-invalid': errors?.OtherID }" maxlength="9">
                </div>
                <div class="col-md-4 d-flex align-items-center gap-4" v-if="isSSN_NAVisible">
                    <input type="checkbox" id="ssn_na" v-model="dependentStore.Person.SSN_NA">
                    <label for="ssn_na">
                        Please check the box if you have not yet acquired a SSN for this Dependent.
                    </label>
                </div>
            </div>
            <div class="form-group row mb-4">
                <div class="col-md-3">
                    <label for="salutation" class="col-form-label text-md-right">Salutation</label>
                    <select class="form-control" id="salutation" v-model="dependentStore.Person.Salutation">
                        <option v-for="salutation in data.Salutations" :value="salutation.CodeID"
                            :key="salutation.CodeID">
                            {{ salutation.Description }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="first_name" class="col-form-label text-md-right">
                        First Name <span class="text-danger">*</span></label>
                    <input type="text" id="first_name" v-model="dependentStore.Person.FirstName" maxlength="12"
                        class="form-control" :class="{ 'is-invalid': errors?.FirstName }" required>
                </div>
                <div class="col-md-3">
                    <label for="middle_name" class="col-form-label text-md-right">
                        Middle Name</label>
                    <input type="text" id="middle_name" v-model="dependentStore.Person.MiddleName" maxlength="12"
                        class="form-control">
                </div>

                <div class="col-md-3">
                    <label for="last_name" class="col-form-label text-md-right">
                        Last Name <span class="text-danger">*</span></label>
                    <input type="text" id="last_name" v-model="dependentStore.Person.LastName" maxlength="25"
                        class="form-control" :class="{ 'is-invalid': errors?.LastName }" required>
                </div>
            </div>
            <div class="form-group row mb-4">
                <div class="col-md-2">
                    <label for="suffix" class="col-form-label text-md-right">Suffix</label>
                    <select class="form-control" id="suffix" v-model="dependentStore.Person.NameSuffix">
                        <option value="0">Select Name Suffix</option>
                        <option v-for="suffix in data.NameSuffixes" :value="suffix.CodeID" :key="suffix.CodeID">
                            {{ suffix.Description }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label for="date_of_birth" class="col-form-label text-md-right">Date of Birth <span
                            class="text-danger">*</span></label>
                    <input type="date" id="date_of_birth" v-model="dependentStore.Person.DateofBirth"
                        :class="{ 'is-invalid': errors?.DateofBirth }" max="{{ now()->format('Y-m-d') }}"
                        class="form-control" required>
                </div>
                <div class="col-md-2">
                    <label for="gender" class="col-form-label text-md-right">Gender</label>
                    <select class="form-control" id="gender" v-model="dependentStore.Person.Sex">
                        <option value="0"> Gender </option>
                        <option v-for="(gender, key) in data.Genders" :value="gender" :key="key">
                            {{ gender }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="phone_number" class="col-form-label text-md-right"> Telephone Number </label>
                    <input type="text" id="phone_number" v-model="dependentStore.PhoneNumber" class="form-control">
                </div>
                <div class="col-md-3">
                    <label for="email" class="col-form-label text-md-right"> Email Address </label>
                    <input type="email" id="email" v-model="dependentStore.Email" class="form-control">
                </div>
            </div>
            <div class="form-group row mb-4" id="marital_data" v-if="isMaritalStatusVisible">
                <div class="col-md-6">
                    <label for="marital_status" class="col-form-label text-md-right">Marital Status</label>
                    <select class="form-control" :class="{ 'is-invalid': errors?.MaritalStatus }" id="marital_status"
                        v-model="dependentStore.Person.MaritalStatus">
                        <option value="0"> Marital Status </option>
                        <option v-for="maritalStatus in data.MaritalStatuses" :value="maritalStatus.CodeID"
                            :key="maritalStatus.CodeID">
                            {{ maritalStatus.Description }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6" v-if="isMarriageDateVisible">
                    <label for="marriage_date" class="col-form-label text-md-right"> Marriage/Affidavit Date <span
                            class="text-danger">*</span>
                    </label>
                    <input type="date" id="marriage_date" v-model="dependentStore.Person.MarriageDate"
                        :max="new Date().toISOString().split('T')[0]" class="form-control"
                        :class="{ 'is-invalid': errors?.MarriageDate }">
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary">Back</a>
                <button type="button" class="btn btn-secondary" @click="handleStep2"> Step 2 </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, ref, defineComponent, computed } from 'vue';
import { useDependentStore } from '@/stores/dependent';

export default defineComponent({
    setup(_, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const requiredFields = ['Relationship', 'FirstName', 'LastName', 'DateofBirth'];
        const dependentStore = useDependentStore();
        const isSSN_NAVisible = computed(() => dependentStore.Person.Relationship == 162);
        const isMaritalStatusVisible = computed(() => dependentStore.Person.Relationship == 181);
        const isMarriageDateVisible = computed(() => dependentStore.Person.MaritalStatus == 1);
        const errors: Ref<{ [key: string]: boolean }> = ref({});
        const isValidStep1 = ref(true);

        const displaySecurityNumber = (type: string) => {
            const countryCode = dependentStore.Person.CitizenshipCode;

            if (countryCode === 1031 && type === 'SSN') {
                return true;
            }
            else if (countryCode === 623 && type === 'SIN') {
                return true;
            }
            else if (countryCode === 1032 && type === 'OtherID') {
                return true
            }

            return false;
        }

        const closeModal = () => {
            emit('close');
        }

        const formatSSN = () => {
            if (dependentStore.Person.SSN) {
                let ssn = dependentStore.Person.SSN.replace(/\D/g, '');  // Remove non-numeric characters

                if (ssn.length <= 3) {
                    dependentStore.Person.SSN = ssn;
                } else if (ssn.length <= 5) {
                    dependentStore.Person.SSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                } else {
                    dependentStore.Person.SSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
                }
            }
        }

        const handleStep2 = () => {
            isValidStep1.value = true;

            // Reset all errors to false
            for (const field in errors.value) {
                errors.value[field] = false;
            }

            requiredFields.forEach(field => {
                const value = dependentStore.Person[field as keyof typeof dependentStore.Person];

                if (value === 0 || !value || (typeof value === 'string' && value.trim() === '')) {
                    errors.value[field] = true; // error if the value is 0 | null | empty
                    isValidStep1.value = false;
                }
            });

            if (isMaritalStatusVisible.value && !dependentStore.Person.MaritalStatus) {
                errors.value['MaritalStatus'] = true;
                isValidStep1.value = false;
            }

            if (isMarriageDateVisible.value && !dependentStore.Person.MarriageDate) {
                errors.value['MarriageDate'] = true;
                isValidStep1.value = false;
            }

            if (!dependentStore.Person.SSN_NA) {
                if (displaySecurityNumber('SSN') && !dependentStore.Person.SSN) {
                    errors.value['SSN'] = true;
                    isValidStep1.value = false;
                }

                if (displaySecurityNumber('SIN') && !dependentStore.Person.SIN) {
                    errors.value['SIN'] = true;
                    isValidStep1.value = false;
                }

                if (displaySecurityNumber('OtherID') && !dependentStore.Person.OtherID) {
                    errors.value['OtherID'] = true;
                    isValidStep1.value = false;
                }
            }

            // emit event if is Valid
            if (isValidStep1.value) {
                emit("check-data", isValidStep1.value);
            }
        }

        return {
            errors,
            dependentStore,
            isSSN_NAVisible,
            data: dependentStore.data,
            isMaritalStatusVisible,
            isMarriageDateVisible,
            displaySecurityNumber,
            handleStep2,
            closeModal,
            formatSSN
        }
    }
})
</script>