import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_DependentStep1 = _resolveComponent("DependentStep1")!
  const _component_DependentStep2 = _resolveComponent("DependentStep2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      errors: _ctx.errors
    }, null, 8, ["success", "errors"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
          : (_openBlock(), _createElementBlock("form", _hoisted_3, [
              (!_ctx.isValidStep1)
                ? (_openBlock(), _createBlock(_component_DependentStep1, {
                    key: 0,
                    onCheckData: _ctx.checkData,
                    onClose: _ctx.closeModal
                  }, null, 8, ["onCheckData", "onClose"]))
                : (_openBlock(), _createBlock(_component_DependentStep2, {
                    key: 1,
                    submitting: _ctx.loadingSubmit,
                    onCheckData: _ctx.checkData,
                    onSubmitData: _ctx.handleSubmit
                  }, null, 8, ["submitting", "onCheckData", "onSubmitData"]))
            ]))
      ])
    ])
  ], 64))
}