<template>
    <div class="row">
        <h5 class="mb-4">Sort By</h5>

        <table cellpadding="10" class="sorting-fields">
            <tr>
                <th>Work Period</th>
                <td><label for=""><input v-model="localSorting.PostedDate" value="0" @change="emitUpdate" type="radio">Newest-Oldest</label></td>
                <td><label for=""><input v-model="localSorting.PostedDate" value="1" @change="emitUpdate" type="radio">Oldest-Newest</label></td>
            </tr>
            <tr>
                <th>Member's Name (Last, First)</th>
                <td><label for=""><input v-model="localSorting.Person" value="0" @change="emitUpdate" type="radio">A-Z</label></td>
                <td><label for=""><input v-model="localSorting.Person" value="1" type="radio">Z-A</label></td>
            </tr>
            <tr>
                <th>Employer</th>
                <td><label for=""><input v-model="localSorting.Employer" value="0" @change="emitUpdate" type="radio">A-Z</label></td>
                <td><label for=""><input v-model="localSorting.Employer" value="1" @change="emitUpdate" type="radio">Z-A</label></td>
            </tr>
            <tr>
                <th>Production</th>
                <td><label for=""><input v-model="localSorting.Production" value="0" @change="emitUpdate" type="radio">A-Z</label></td>
                <td><label for=""><input v-model="localSorting.Production" value="1" @change="emitUpdate" type="radio">Z-A</label></td>
            </tr>
            <tr>
                <th>Fund</th>
                <td><label for=""><input v-model="localSorting.Fund" value="0" @change="emitUpdate" type="radio">A-Z</label></td>
                <td><label for=""><input v-model="localSorting.Fund" value="1" @change="emitUpdate" type="radio">Z-A</label></td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { reactive, defineComponent, PropType } from 'vue';
import { LocalReportSortedFields } from '@/interfaces/local';


export default defineComponent({
    props: {
        sorting: {
            type: Object as PropType<LocalReportSortedFields>,
            required: true,
        },
    },

    setup(props, { emit }) {
        const localSorting = reactive({ ...props.sorting });

        const emitUpdate = () => {
            emit('update-sorting', localSorting);
        };

        return {
            localSorting,
            emitUpdate
        };
    }
})
</script>

<style scoped>
.sorting-fields input[type="radio"] {
    margin-right: 7px;
}
</style>