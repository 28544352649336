<template>
    <div class="row">
        <h5 class="mb-4">Output Columns</h5>
        <div class="col-md-12">
            <ul>
                <li><label for=""><input v-model="localFields.WorkPeriod" @change="emitUpdate" type="checkbox"></label>Work Period</li>
                <li><label for=""><input v-model="localFields.Name" @change="emitUpdate" type="checkbox"></label>Member's Name</li>
                <li><label for=""><input v-model="localFields.PersonID" @change="emitUpdate" type="checkbox"></label>Member's NBF ID</li>
                <li><label for=""><input v-model="localFields.Employer" @change="emitUpdate" type="checkbox"></label>Employer</li>
                <li><label for=""><input v-model="localFields.Production" @change="emitUpdate" type="checkbox"></label>Production</li>
                <li><label for=""><input v-model="localFields.Payor" @change="emitUpdate" type="checkbox"></label>Payor</li>
                <li><label for=""><input v-model="localFields.ReceiptDate" @change="emitUpdate" type="checkbox"></label>Receipt Dates</li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, defineComponent, PropType } from 'vue';
import { LocalReportSelectedFields } from '@/interfaces/local';


export default defineComponent({
    props: {
        fields: {
            type: Object as PropType<LocalReportSelectedFields>,
            required: true,
        },
    },

    setup(props, { emit }) {
        const localFields = reactive({ ...props.fields });

        const emitUpdate = () => {
            emit('update-fields', localFields);
        };

        return {
            localFields,
            emitUpdate
        };
    }
})
</script>