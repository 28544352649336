import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = { class: "action-buttons" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_PlanGeneral = _resolveComponent("PlanGeneral")!
  const _component_MultiStep = _resolveComponent("MultiStep")!
  const _component_UpdateInfoStep1 = _resolveComponent("UpdateInfoStep1")!
  const _component_UpdateInfoStep2 = _resolveComponent("UpdateInfoStep2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      info: _ctx.info,
      errors: _ctx.errors
    }, null, 8, ["success", "info", "errors"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PlanGeneral, { text: "All Updates to your personal information will immediately and permanently change your account with the Fund\n                        Office." }),
      _createVNode(_component_MultiStep, {
        steps: 2,
        activeStep: _ctx.currentStep
      }, null, 8, ["activeStep"]),
      (_ctx.currentStep === 1)
        ? (_openBlock(), _createBlock(_component_UpdateInfoStep1, {
            key: 0,
            onNext: _ctx.goToNextStep,
            onClose: _ctx.closeModal
          }, null, 8, ["onNext", "onClose"]))
        : (_openBlock(), _createBlock(_component_UpdateInfoStep2, { key: 1 })),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.currentStep === 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
            }, " Discard"))
          : _createCommentVNode("", true),
        (_ctx.currentStep === 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "btn btn-primary",
              disabled: _ctx.loadingSubmit,
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.loadingSubmit ? 'Saving...' : 'Save'), 9, _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}