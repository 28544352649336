<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <DataTable v-if="dependents.rows?.length" :table="dependents" classes="striped small" />
        <div v-else-if="dependents?.rows.length === 0 || !dependents" class="col-md-12 text-center my-4">
            <span> Currently there are no dependents on file </span>
        </div>

        <!-- Account Actions -->
        <div class="account-actions">
            <div class="action-buttons">
                <a href="" class=" btn btn-tertiary btn-large plan-modal" @click.prevent="openModal(
                    'beneficiaries',
                    'My Beneficiaries',
                    'plan-tables-striped beneficiary',
                    null,
                    null,
                    { name: 'Add Beneficiary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }
                )">
                    Beneficiaries
                </a>
                <a href="" class="btn btn-primary plan-modal"
                    @click.prevent="openModal('add-dependent', 'Add Dependent', 'plan-form plan-upload')"> Add Dependent
                </a>
            </div>
        </div>
    </div>

    <!-- <router-link
        :to="{ name: 'dependent-documents', query: { PartnerID: dependent.PartnerID, DependentID: dependent.DependentID, Status: dependent.Status } }">
        <i class="fas fa-paperclip"> </i>
    </router-link> -->
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import { Dependent, TableData, RowData } from '@interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useDependentStore } from '@/stores/dependent';
import DataTable from '@components/form/DataTable.vue';
import axios from 'axios';

export default {
    components: {
        DataTable
    },
    setup() {
        const loading = ref(true);
        const dependents = computed(() => useDependentStore().dependents);
        const modalStore = useModalStore();

        const fetchData = async () => {
            await useDependentStore().fetchDependents();
            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            dependents,
            loading,
            openModal: modalStore.openModal
        };
    }
};
</script>