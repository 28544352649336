import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!

  return ($setup.loading)
    ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.documentUrl)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              src: $setup.documentUrl,
              width: "100%",
              height: "600"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, "Couldn't load the document."))
      ]))
}