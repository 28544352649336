<template>
    <div class="table-wrapper" v-if="patients?.rows">
        <h4> Who is this claim for? </h4>
        <DataTable type="radio" classes="striped small" :table="patients" :selected="selectedPatient" @change="handleChange" />
    </div>
    <a href="" class="btn btn-secondary plan-modal add-dependent"
        @click.prevent="openModal('add-dependent', 'Add Dependent', 'plan-form plan-upload')"> + Add Dependent </a>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { TableData } from '@/interfaces/interfaces';
import { useClaimStore } from '@/stores/claim';
import { useModalStore } from '@/stores/modal';
import DataTable from '@/components/form/DataTable.vue';

export default defineComponent({
    props: {
        patients: [] as PropType<TableData>,
    },
    components: {
        DataTable,
    },
    setup() {
        const claimStore = useClaimStore();

        const handleChange = (event: Event) => {
            const target = event.target as HTMLInputElement | null;

            if (target?.value) {
                claimStore.setPatient(target.value);
            }
        }

        return {
            selectedPatient: claimStore.patient,
            handleChange,
            openModal: useModalStore().openModal
        }
    }
})
</script>