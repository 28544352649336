<template>
    <div class="row">
        <h5 class="mb-4">Filters</h5>
        <div class="form-group mb-4">
            <div class="local-options">
                <div>
                    <input type="radio" id="local0" value="0" v-model="localFilters.Local" @change="emitUpdate">
                    <label for="local0">Work in juristiction</label>
                </div>

                <div>
                    <input type="radio" id="local1" value="1" v-model="localFilters.Local" @change="emitUpdate">
                    <label for="local1">Home Local</label>
                </div>

                <div>
                    <input type="radio" id="local2" value="2" v-model="localFilters.Local" @change="emitUpdate">
                    <label for="local2">Both</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Work Period Start</label>
                    <input type="date" class="form-control" v-model="localFilters.PeriodStart" @change="emitUpdate">
                </div>
                <div class="col-md-6">
                    <label>Work Period End</label>
                    <input type="date" class="form-control" v-model="localFilters.PeriodEnd" @change="emitUpdate">
                </div>
            </div>
        </div>

        <div class="form-group mb-4">
            <div class="row">
                <div class="col-md-6">
                    <label>Employers</label>
                    <Multiselect v-model="localFilters.Employers" placeholder="Type to search" label="Name"
                        track-by="ID" :options="employers" :loading="loadingProductions" :multiple="true"
                        :hide-selected="true" :searchable="true" :close-on-select="true" @select="handleEmployersChange"
                        @open="handleEmployersOpen" @search-change="handleEmployersSearch" @change="emitUpdate">
                    </Multiselect>
                </div>
                <div class="col-md-6">
                    <label>Productions</label>
                    <Multiselect v-model="localFilters.Productions" placeholder="Type to search" label="Name"
                        track-by="ID" :options="productions" :multiple="true" :hide-selected="true" :searchable="true"
                        :close-on-select="true" @select="handleProductionChange" @open="handleProductionsOpen"
                        @search-change="handleProductionsSearch" :loading="loadingProductions" @change="emitUpdate">
                    </Multiselect>
                </div>
            </div>
        </div>

        <div class="form-group mb-4">
            <div class="row">
                <div class="col-md-6">
                    <label for="Payors">Payors</label>
                    <Multiselect v-model="localFilters.Payors" placeholder="Type to search" label="Name" track-by="ID"
                        :options="payors" :multiple="true" :hide-selected="true" :searchable="true"
                        :close-on-select="true" @select="handlePayors" @open="handlePayorsOpen"
                        @search-change="handlePayorsSearch" @change="emitUpdate">
                    </Multiselect>
                </div>
                <div class="col-md-6">
                    <label for="Employer">Funds</label>
                    <Multiselect v-model="localFilters.Funds" placeholder="Type to search" label="Name" track-by="ID"
                        :options="funds" :multiple="true" :hide-selected="true" :searchable="true"
                        :close-on-select="true" @select="handleFunds" @search-change="handleFundsSearch"
                        @change="emitUpdate">
                    </Multiselect>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <label for="Employer">Member's NBF ID / SSN</label><br />
                    <input type="text" class="form-control" v-model="localFilters.PersonID" @change="emitUpdate">
                </div>
                <div class="col-md-4 col-sm-12">
                    <label for="FirstName">Member's First Name</label>
                    <br />
                    <input type="text" class="form-control" v-model="localFilters.FirstName" @change="emitUpdate">
                </div>
                <div class="col-md-4 col-sm-12">
                    <label for="LastName">Member's Last Name</label>
                    <br />
                    <input type="text" class="form-control" v-model="localFilters.LastName" @change="emitUpdate">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, PropType, defineComponent, computed } from 'vue';
import { LocalReportFilters, Employer } from '@/interfaces/local';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

interface FundOption {
    Id: number,
    Name: string
}

export default defineComponent({
    components: {
        Multiselect,
    },

    props: {
        filters: {
            type: Object as PropType<LocalReportFilters>,
            required: true,
        },
    },

    setup(props, { emit }) {
        const localFilters = reactive({ ...props.filters });
        const loadingEmployers = ref(false);
        const loadingProductions = ref(false);
        const employers = ref([]);
        const productions: Ref<Employer[]> = ref([]);
        const payors: Ref<Employer[]> = ref([]);
        const funds: Ref<FundOption[]> = ref([]);
        const errors: Ref<string[]> = ref([]);
        const allOption = { ID: 0, Name: 'ALL' };

        const emitUpdate = () => {
            console.log(">>> Emit");
            console.log(localFilters);
            emit('update-filters', localFilters);
        };

        const hasEmployers = computed(() => localFilters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => localFilters.Productions?.some(prod => prod.ID !== 0));
        const hasPayors = computed(() => localFilters.Payors?.some(payor => payor.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            loadingEmployers.value = true;

            try {
                const response = await axios.get(`api/local/employers?query=${query}`);
                employers.value = response.data;
            }
            catch (error) {
                console.error('Error searching employer:', error);
            }

            loadingEmployers.value = false;
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            loadingProductions.value = true;
            const employerIds = localFilters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            try {
                const response = await axios.get(`api/local/adhoc-productions?s=${query}&EmployerIDs=${employerIds}&local=${localFilters.Local}`);
                productions.value = response.data;
            }
            catch (error) {
                console.error('Error searching production:', error);
            }

            loadingProductions.value = false;
        };

        // Handle payors search
        const handlePayorsSearch = async (s: string) => {
            loadingProductions.value = true;
            const employerIds = localFilters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            const productionIds = localFilters.Productions?.map((payor: Employer) => {
                if (payor.ID !== 0)
                    return payor.ID
            });

            try {
                const response = await axios.get(`api/local/adhoc-payors?s=${s}&EmployerIDs=${employerIds}&ProductionsIDs=${productionIds}&local=${localFilters.Local}`);
                payors.value = response.data;
            }
            catch (error) {
                console.error('Error searching payors:', error);
            }

            // loadingProductions.value = false;
        };

        const handleFundsSearch = async (s: string) => {
            try {
                const response = await axios.get(`api/local/adhoc-funds?s=${s}`);
                funds.value = response.data;
            }
            catch (error) {
                console.error('Error searching payors:', error);
            }
        };

        const handleEmployerChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                localFilters.Employers = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(localFilters.Employers)).filter((type: Employer) => type.ID !== 0)
                localFilters.Employers = [...filteredTypes];
                handleProductions();
            }
        }

        const handleProductions = async () => {
            await handleProductionsSearch('');

            if (localFilters.Productions && localFilters.Productions[0]?.ID !== 0) {
                localFilters.Productions = localFilters.Productions?.filter((prod: Employer) => productions.value.some(p => p.ID === prod.ID)) || [];
            }
        }

        const handlePayors = async () => {
            await handlePayorsSearch('');

            if (localFilters.Payors && localFilters.Payors[0]?.ID !== 0) {
                localFilters.Payors = localFilters.Payors?.filter((pay: Employer) => payors.value.some(p => p.ID === pay.ID)) || [];
            }
        }

        const handleFunds = async () => {
            await handleFundsSearch('');

            if (localFilters.Funds && localFilters.Funds[0]?.ID !== 0) {
                localFilters.Funds = localFilters.Funds?.filter((fund: Employer) => funds.value.some(p => p.Id === fund.ID)) || [];
            }
        }

        const handleProductionChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                localFilters.Productions = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(localFilters.Productions)).filter((type: Employer) => type.ID !== 0)
                localFilters.Productions = [...filteredTypes];
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = localFilters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                handleProductionsSearch(keyword);
            }
        };

        const handlePayorsOpen = async (s: string) => {
            const employerIds = localFilters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = s ?? '';

            if (!employerIds?.includes(0)) {
                handlePayorsSearch(keyword);
            }
        };

        return {
            localFilters,
            employers,
            loadingEmployers,
            loadingProductions,
            productions,
            payors,
            funds,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handlePayors,
            handleFunds,
            handlePayorsSearch,
            handleFundsSearch,
            handlePayorsOpen,
            errors,
            emitUpdate,
        };
    }
})
</script>

<style scoped>
.local-options {
    padding-bottom: 20px;
    display: flex;
    justify-content: start;
}

.local-options>div {
    padding-left: 15px;
}

.local-options>div label {
    padding-left: 7px;
}
</style>