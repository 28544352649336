<template>
    <div :class="['text-input', customClass, { 'error': error }]">
        <label :for="label" class="body-text">{{ label }}</label>
        <input :type="type" :id="label" :name="name" :placeholder="placeholder" :pattern="pattern ? pattern : undefined"
            :maxlength="max" :readonly="readonly" :required="required" class="links" :value="inputValue"
            @input="onInput"
        />
        <i v-if="type === 'password'" class="show-password icon-eye"></i>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String as PropType<'text' | 'password' | 'email' | 'tel'>,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        max: {
            type: Number,
            default: 50,
        },
        pattern: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'text-group',
        },
        modelValue: {
            type: String || Number,
            default: '',
        },
        reset: Boolean,
        error: {
            type: Boolean,
            default: false
        },
    },

    setup(props, { emit }) {
        const inputValue = ref(props.modelValue);

        watch(() => props.modelValue, (newValue) => {
            inputValue.value = newValue;
        });

        const onInput = (event: Event) => {
            const target = event.target as HTMLInputElement | null;

            if (target) {
                const value = target.value;
                const newValue = props.name === 'ssn' ? formatSSN(value) : value;
                target.value = newValue ?? '';

                emit('update:modelValue', newValue);
            }
        }

        const formatSSN = (value: string) => {
            let ssn = value.replace(/\D/g, '');  // Remove non-numeric characters
            let ssnValue = ''

            if (ssn.length <= 3) {
                ssnValue = ssn;
            } else if (ssn.length <= 5) {
                ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
            } else {
                ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
            }

            return ssnValue;
        }

        return {
            inputValue,
            onInput
        };
    },
});
</script>