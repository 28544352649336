<template>
    <div class="radio-group">
        <label v-if="label" class="main-label">{{ label }}</label>
        <div class="radio-buttons">
            <div v-for="(option, index) in options" :key="index" class="radio-button">
                <input type="radio" :id="`${name}${index + 1}`" :name="name" :value="option.label"
                    v-model="selectedValue" />
                <label class="body-text" :for="`${name}${index + 1}`">
                    {{ option.value }}
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import { RadioOption } from '@/interfaces/interfaces';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<RadioOption[]>,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
    },
    setup(props, { emit }) {
        const selectedValue = ref(props.modelValue);

        watch(() => props.modelValue, (newValue) => {
            selectedValue.value = newValue;
        });

        watch(selectedValue, (newValue) => {
            emit('update:modelValue', newValue);
            // emit('change-type');
        });

        return {
            selectedValue,
        };
    },
});
</script>