import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "modal-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["main", { 'overflow-hidden': _ctx.modals?.length }]),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
  }, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_SidebarComponent, {
          key: 0,
          active: _ctx.activeMenu,
          onOpenMenu: _ctx.openMenu
        }, null, 8, ["active", "onOpenMenu"]))
      : _createCommentVNode("", true),
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_MobileHeader, {
          key: 1,
          onOpenModal: _ctx.openModal,
          onOpenMenu: _ctx.openMenu
        }, null, 8, ["onOpenModal", "onOpenMenu"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["main-content", { 'authenticated': _ctx.isAuthenticated, 'fixed': _ctx.activeMenu }]),
      id: "content"
    }, [
      _withDirectives(_createVNode(_component_HeaderComponent, {
        onOpenModal: _ctx.openModal,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, null, 8, ["onOpenModal"]), [
        [_vShow, _ctx.showHeader]
      ]),
      _createVNode(_component_router_view)
    ], 2),
    _createElementVNode("div", {
      class: "modal-container",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modals, (modal, index) => {
        return (_openBlock(), _createBlock(_component_ModalComponent, {
          key: index,
          isVisible: modal.isVisible,
          index: index,
          modalType: modal.type,
          title: modal.title,
          classNames: modal.classes,
          fund: modal.fund,
          modalProps: modal.modalProps,
          headerButton: modal.headerButton,
          onClose: _ctx.closeModal,
          onCloseAbove: _ctx.closeAbove
        }, null, 8, ["isVisible", "index", "modalType", "title", "classNames", "fund", "modalProps", "headerButton", "onClose", "onCloseAbove"]))
      }), 128))
    ]),
    (_ctx.modals?.length || _ctx.activeMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}