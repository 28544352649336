<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">About Your Members</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 w-100">
                        <h6>About Your Members</h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <router-link :to="{ name: 'local.roster' }">
                                {{ userName }} Roster
                            </router-link>
                            <router-link :to="{ name: 'user.demographic' }">
                                Missing Demographic Information
                            </router-link>
                            <router-link :to="{ name: 'local.member-search' }">
                                Member Search
                            </router-link>
                            <span>Member Work History</span>
                            <router-link :to="{ name: 'local.coverage-search' }">
                                Member Current Coverage Search
                            </router-link>
                            <router-link :to="{ name: 'local.create-report' }">
                                Create your own report
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column gap-2 w-100">
                        <h6>H&W - BAA</h6>
                        <a href="#">Upload H&W Business Associate Agreement</a>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-flex flex-column gap-2 w-100">
                        <h6>Reports from Fund Office</h6>
                        <span>Most Recent</span>
                        <div class="d-flex flex-column gap-2 w-100">
                            <a href="#">Monthly Member Work History Report</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
    setup() {
        const userName = useAuthStore().userName;

        return {
            userName
        }
    }
})
</script>

<style scoped>
a {
    text-decoration: none;
}

.card-title {
    text-decoration: none;
    color: #0d6efd;
}

.card-title:hover {
    cursor: pointer;
}
</style>
