<template>
  <!-- <div class="bordered-card bordered-card-beneficiary" :class="{ 'has-changes': beneficiary?.Additional?.PendingBene }"> -->
  <div class="bordered-card bordered-card-beneficiary">
    <div class="bordered-card-header">
      <h2 class="d-flex gap-2">{{ beneficiary?.Name }}
        <div v-if="beneficiary?.Additional?.PendingDeletion" class="label_pending label_pending__danger">
          <small class="label label_danger"> Pending Deletion </small>
          <i class="iatse-icon-info-circle"> </i>
        </div>
        <div v-else-if="beneficiary?.Additional?.PendingBene" class="label_pending">
          <small class="label label_blue"> Pending Approval </small>
          <i class="iatse-icon-info-circle"> </i>
        </div>
        <div v-else-if="beneficiary?.Additional?.PendingChange" class="label_pending">
          <small class="label label_blue"> Pending Address Change </small>
          <i class="iatse-icon-info-circle"> </i>
        </div>
      </h2>

      <div class="action-buttons">
        <i class="iatse-icon-edit plan-modal" @click="handleEditBene(beneficiary)"></i>
        <i class="iatse-icon-trash plan-modal" @click="handleDelete(beneficiary)"></i>
      </div>
    </div>
    <!-- Beneficiary Card -->
    <div class="card-wrapper">
      <BeneficiaryCard mode="single" :title="beneficiary.Name" :data="beneficiary.General" :beneficiary="beneficiary" :actionButtons="true"
        customClass="single-card" />
    </div>

    <!-- Table Card -->
    <div class="bordered-card table-card">
      <div class="bordered-card-header">
        <h4>
          Allocations for Beneficiaries
          <div v-if="beneficiary?.Additional?.HasChanges" class="label_pending">
            <small class="label label_blue">Pending Approval</small>
            <i class="iatse-icon-info-circle"> </i>
          </div>
        </h4>
        <p class="text-underline plan-modal" @click="handleEditAssignments">Edit Assignments</p>
      </div>
      <div class="plan-content" v-if="beneficiary.Allocations">
        <DataTable :table="beneficiary.Allocations" :classes="{
        'striped small': true,
        'has-changes': beneficiary?.Additional?.HasChanges
      }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { Beneficiary } from "@/interfaces/beneficiary";
import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import DataTable from '@/components/form/DataTable.vue';

export default defineComponent({
  components: {
    BeneficiaryCard,
    DataTable
  },
  props: {
    beneficiary: {
      type: Object as PropType<Beneficiary>,
      required: true
    },
  },
  setup(props) {
    const beneStore = useBeneficiaryStore();
    const openModal = useModalStore().openModal;

    const handleEditAssignments = () => {
      beneStore.isAddAction = false;
      beneStore.isEditAction = false;
      beneStore.isDeleteAction = false;
      beneStore.reloadAssignments();
      openModal('assign-fund', 'Assign Fund To Beneficiary', 'plan-upload');
    }

    const handleEditBene = (bene: Beneficiary) => {
      beneStore.isEditAction = true;
      beneStore.getEditBene(bene)
      openModal('add-beneficiary', 'Edit Beneficiary', 'plan-form plan-upload edit-beneficiary', null);
    }

    const handleDelete = async (beneficiary: Beneficiary) => {
      beneStore.isDeleteAction = true;
      beneStore.isAddAction = false;
      beneStore.isEditAction = false;
      beneStore.deletedBeneficiary = beneficiary?.BeneficiaryID ?? null;

      if (beneStore.beneficiaries.length > 1 && beneficiary?.Allocations?.rows?.length) {
        openModal('success-modal', null, 'modal-success', null, beneStore.getDeleteModalProps);
      }
      else {
        await beneStore.handleSubmitNewBenes(true);
        openModal('success-modal', null, 'modal-success', null, beneStore.getSuccessDeleteModalProps);
        await beneStore.fetchBeneficiaries();
      }
    }

    return {
      openModal,
      handleEditBene,
      handleEditAssignments,
      handleDelete
    }
  }
});
</script>