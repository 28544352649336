<template>
    <div :class="['plan', { [classNames]: true, [modalType]: true, 'active': isVisible, 'slide-out': !isVisible && modalType !== 'slide-out' }]"
        :style="modalStyles" @click.self="handleClickOutside">
        <div class="plan-header" v-if="title">
            <div class="plan-breadcrumbs">
                <div class="plan-breadcrumbs-left">
                    <i class="iatse-icon-left-arrow close-plan-modal" @click="closeModal"></i>
                    <h3>{{ title }}</h3>
                </div>
                <div class="plan-switch">
                    <a v-if="localHeaderButton" href="#" class="btn btn-primary plan-modal"
                        @click.prevent="handleButtonModal">
                        {{ headerButton.name }}
                    </a>
                    <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
                </div>
            </div>
        </div>
        <component :is="getModalContent(modalType)" @close="closeModal" @close-above="closeAbove"
            @handle-submit="handleSubmit" @toggle-header-button="toggleHeaderButton" :fund="fund"
            :modalProps="modalProps" :index="index" />
    </div>
    <div v-if="modalType === 'success-modal'" class="modal-overlay success-modal-overlay"></div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';

export default defineComponent({
    name: 'ModalComponent',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modalType: {
            type: String,
            default: ''
        },
        classNames: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        fund: {
            type: String,
            default: null,
        },
        modalProps: {
            type: Object,
            default: null,
        },
        headerButton: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();
        const localHeaderButton = ref(props.headerButton);

        const closeModal = () => {
            emit('close', props.index);
        };

        const closeAbove = (index: number) => {
            emit('close-above', index);
        };

        const modalStyles = computed(() => {
            const offset = 290 + Number(props.index * 20);

            return {
                "--modal-width": `calc(100% - ${offset}px)`,
            };
        });

        const handleClickOutside = () => {
            modalStore.closeAbove(props.index);
        }

        const handleButtonModal = () => {
            if (localHeaderButton.value.name === 'Add Beneficiary') {
                useBeneficiaryStore().isAddAction = true;
                useBeneficiaryStore().isEditAction = false;
                useBeneficiaryStore().resetNewBene();
                console.log(localHeaderButton);
            }

            modalStore.openModal(props.headerButton.action, props.headerButton.name, props.headerButton.classes)
        }

        const toggleHeaderButton = (payload: Record<string, any>) => {
            localHeaderButton.value = payload;
        }


        return {
            modalStyles,
            localHeaderButton,
            closeModal,
            closeAbove,
            handleClickOutside,
            toggleHeaderButton,
            handleButtonModal,
            handleSubmit: modalStore.handleSubmit,
            getModalContent: modalStore.getModalContent,
        };
    }
});
</script>