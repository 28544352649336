<template>
    <div class="container mt-4">
        <div class="row">
            <LocalReportStep1 v-if="currentStep === 1" :filters="filters.FilterFields" @update-filters="updateFilterFields" />
            <LocalReportStep2 v-if="currentStep === 2"  :fields="filters.SelectedFields" @update-fields="updateSelectedFields" />
            <LocalReportStep3 v-if="currentStep === 3" :sorting="filters.SortedFields" @update-sorting="updateSortedFields" />

            <br/>
            <div class="row mt-4">
                <div class="col-md-2">
                    <button class="btn btn-sm btn-secondary" @click="goToPreviousStep" v-if="currentStep > 1">Back</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-sm btn-secondary" @click="goToNextStep" v-if="currentStep < 3">Next</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary btn-submit" @click="submitReport" v-if="currentStep === 3" :disabled="loading">Submit 
                        <span v-if="loading" width="20">
                            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform 
                                        attributeName="transform" 
                                        attributeType="XML" 
                                        type="rotate"
                                        dur="1s" 
                                        from="0 50 50"
                                        to="360 50 50" 
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <br/>
            <div class="row" v-if="filePath">
                <div class="col-md-12">
                    <p>You AdHoc Report is ready. <a href="javascript:;" @click="() => downloadFile(filePath)">Download</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, reactive, Ref } from 'vue';
import { useAuthStore } from '@/stores/auth'
import axios from 'axios';
import { LocalReportFilters, LocalReportSelectedFields, LocalReportSortedFields } from '@/interfaces/local';
import LocalReportStep1 from '@/components/local/LocalReportStep1.vue';
import LocalReportStep2 from '@/components/local/LocalReportStep2.vue';
import LocalReportStep3 from '@/components/local/LocalReportStep3.vue';

interface Funds {
    [key: string]: string;
}

export default {
    setup() {
        const currentStep = ref<number>(1);
        const allOption = { ID: 0, Name: 'ALL' };
        const success = ref(null);
        const errors: Ref<string[]> = ref([]);
        const loading = ref(false);
        const filePath = ref<string | null>(null);

        // Default values for LocalReportSelectedFields
        const defaultSelectedFields: LocalReportSelectedFields = {
            WorkPeriod: true,
            Name: true,
            PersonID: true,
            Employer: true,
            Production: true,
            Payor: true,
            ReceiptDate: true
        };

        const defaultSortedFields: LocalReportSortedFields = {
            PostedDate: null,
            Person: null,
            Employer: null,
            Production: null,
            Fund: null,
            AmtDue: null,
            AmtPaid: null,
            Payor: null,
            Status: null
        };

        // Initialize the ref with default values including Fields
        const defaultReportFilters: LocalReportFilters = {
            Local: 0,
            Employers: [allOption],
            Productions: [allOption],
            Payors: [allOption],
            Funds: [allOption],
            PersonID: null,
            FirstName: null,
            LastName: null,
            PeriodStart: null,
            PeriodEnd: null
        };

        const filters = reactive({
            FilterFields: defaultReportFilters,
            SelectedFields: defaultSelectedFields,
            SortedFields: defaultSortedFields,
        });

        const updateFilterFields = (updatedFields: LocalReportFilters) => {
            console.log('>>>>>>> Trigger: updateFilterFields');
            filters.FilterFields = { ...updatedFields };
        };

        const updateSelectedFields = (updatedFields: LocalReportSelectedFields) => {
            console.log('>>>>>>> Trigger: updateSelectedFields');
            filters.SelectedFields = { ...updatedFields };
        };

        const updateSortedFields = (updatedFields: LocalReportSortedFields) => {
            console.log('>>>>>>> Trigger: updateSortedFields');
            filters.SortedFields = { ...updatedFields };
        };

        const goToNextStep = (): void => {
            if (currentStep.value < 3) currentStep.value++;
        };

        const goToPreviousStep = (): void => {
            if (currentStep.value > 1) {
                currentStep.value--;
                filePath.value = null;
            }
        };

        const submitReport = async () => {
            loading.value = true;

            try {
                const response = await axios.post(
                    '/api/local/create-report', filters
                );
                filePath.value = response.data.success;
            }
            catch (error) {
                console.error('Failed to fetch file:', error);
                filePath.value = null;
            }
            finally {
                loading.value = false;
            }
        };

        const downloadFile = async(filename: string) => {
            const response = await axios.get(`/api/local/download-report/${filename}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // or extract name from content-disposition header
            document.body.appendChild(link);
            link.click();
            link.parentNode!.removeChild(link);
            window.URL.revokeObjectURL(url);
        }

        const fetchData = async () => {
            await axios.get('api/local/create-report')
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        };

        onMounted(async () => await fetchData());

        return {
            currentStep,
            filters,
            updateFilterFields,
            updateSelectedFields,
            updateSortedFields,
            goToNextStep,
            goToPreviousStep,
            submitReport,
            loading,
            filePath,
            downloadFile
        };
    },

    components: {
        LocalReportStep1,
        LocalReportStep2,
        LocalReportStep3
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}

.btn-submit {
    display: flex;
}

.btn-submit span {
    width: 20px;
}
</style>
