import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "plan-content"
}
const _hoisted_2 = { class: "action-buttons" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_MultiStep = _resolveComponent("MultiStep")!
  const _component_PlanGeneral = _resolveComponent("PlanGeneral")!
  const _component_CoveredCard = _resolveComponent("CoveredCard")!
  const _component_CertificationCondition = _resolveComponent("CertificationCondition")!
  const _component_ClaimStep1 = _resolveComponent("ClaimStep1")!
  const _component_ClaimStep2 = _resolveComponent("ClaimStep2")!
  const _component_ClaimStep3 = _resolveComponent("ClaimStep3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      info: _ctx.info,
      errors: _ctx.errors
    }, null, 8, ["success", "info", "errors"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.currentStep > 0)
            ? (_openBlock(), _createBlock(_component_MultiStep, {
                key: 0,
                steps: 3,
                activeStep: _ctx.currentStep
              }, null, 8, ["activeStep"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PlanGeneral, { text: "NOTE: If you wish to have your claim payment directly deposited into your bank account, please submit a completed MRP claims direct deposit form to claims@iatsenbf.org. It may take up to two weeks for processing of your request as we wait for your bank to verify your banking information. Once we receive verification, we can then start to deposit your claims reimbursement payments directly to your bank account. The form can be found in the Applications/Forms/Documents link on the Fund website. You can also submit the completed direct deposit form online, in the section where you would normally submit an MRP claim. You can also email it to psc@iatsenbf.org." }),
          _createElementVNode("form", {
            id: "claim-submission-form",
            class: _normalizeClass(["form claim-submission", { 'step-3': _ctx.currentStep === 3 }]),
            method: "POST"
          }, [
            _createVNode(_component_CoveredCard, { links: _ctx.links }, null, 8, ["links"]),
            (_ctx.currentStep === 0)
              ? (_openBlock(), _createBlock(_component_CertificationCondition, {
                  key: 0,
                  agreement: _ctx.agreement,
                  "onUpdate:agreement": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.agreement) = $event))
                }, null, 8, ["agreement"]))
              : _createCommentVNode("", true),
            (_ctx.currentStep === 1)
              ? (_openBlock(), _createBlock(_component_ClaimStep1, {
                  key: 1,
                  patients: _ctx.patients
                }, null, 8, ["patients"]))
              : _createCommentVNode("", true),
            (_ctx.currentStep === 2)
              ? (_openBlock(), _createBlock(_component_ClaimStep2, {
                  key: 2,
                  uploadedDocuments: _ctx.uploadedDocuments,
                  loadingUpload: _ctx.loadingUpload,
                  submitting: _ctx.submitting,
                  deleting: _ctx.deleting,
                  notes: _ctx.notes,
                  "onUpdate:notes": _cache[1] || (_cache[1] = ($event: any) => (_ctx.notes = $event)),
                  onHandleUpload: _ctx.handleUpload,
                  onHandleDelete: _ctx.handleDelete
                }, null, 8, ["uploadedDocuments", "loadingUpload", "submitting", "deleting", "notes", "onHandleUpload", "onHandleDelete"]))
              : _createCommentVNode("", true),
            (_ctx.currentStep === 3)
              ? (_openBlock(), _createBlock(_component_ClaimStep3, { key: 3 }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("a", {
                href: "",
                class: "btn btn-secondary plan-modal",
                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleBack && _ctx.handleBack(...args)), ["prevent"])),
                disabled: _ctx.loadingUpload || _ctx.submitting
              }, " Back ", 8, _hoisted_3),
              _createElementVNode("button", {
                class: "btn btn-primary plan-modal",
                onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleNext && _ctx.handleNext(...args)), ["prevent"])),
                disabled: _ctx.loadingUpload || _ctx.submitting || !_ctx.agreement
              }, _toDisplayString(_ctx.submitting ? 'Submitting' : 'Next'), 9, _hoisted_4)
            ])
          ], 2)
        ]))
  ], 64))
}